body {
  background: #333;
  padding: 0;
  margin: 0;

}
@font-face {
    font-family: "adv";
    src: url("../public/ljk_Adventure.ttf");
}
@font-face {
    font-family: "Shock-Brat";
    src: url("https://moondust-starlight.dx-assets.pages.dev/Shock-Brat.ttf")
}
@font-face {
    font-family: "Vasek";
    src: url("https://moondust-starlight.dx-assets.pages.dev/Vasek Italic_0.ttf")
}
.logo img {
    width: 30%;
    z-index: -1;
}
.logo {
    z-index: -1;
}
.header {
    height: 8%;
    width: 100%;
}
a {
    z-index: 9;
    text-decoration: none;
}
div.content {
    height: 100%;
}
.f .card-body > img{
    width: 15%;
    padding-top: 1%;
}
.cards {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.card {
    color: white;
    width: 60%;
    margin: 0 14%;
    border: 1px solid #444;
    height: 100%;
    border-radius: 5px;
    padding: 2% 7%;
}
.card .card-title {
    font-size: 20pt;
    font-family: "Shock-Brat", "adv";
    margin-left: -5%;
}
.header {
    text-align: center;
}
.card .card-body:not(.card:first-child .card-body) {
    font-family: "Vasek";
    font-size: 28pt;
}
.card:first-child {
    margin-top: 5%;
}
@media screen and (max-width: 720px) {
    .header {
        border: solid transparent 1px;
        width: 50%;
        
    }
    .discord a img {
        padding: 11%;
        width: 15%;
    }
    .cards {
        flex-direction: column;
    }
    .footer .desktop {
        display: none;
    }
}
@media screen and (min-width: 720px) {
  .card .card-body:not(.card:first-child .card-body) {
    font-family: "Vasek";
    font-size: 64pt;
}
    .logo {
      transform: scale(0.75);
    }
    .content {
      margin-top: -8%;
    }
    .discord a img {
        float: right;
        width: 10%;
        margin-top: -11.5%;
        margin-right: 10%;
        transform: scale(0.5);
    }
    .cards {
        flex-direction: row;
    }
    * {
      transform: scale(0.95);
    }
    body {
      margin-top: -10%;
    }
    .card {
        margin: 0 20%;
    }
    .footer .mobile {
        display: none;
    }
    .card .card-title {
        font-size: 48pt;
        padding-bottom: 5%;
    }
    .card:first-child .card-title {
        font-size: 52pt;
        padding-bottom: 5%;
    }
    .cards {
        flex-wrap: wrap;
    }
}
.h {
    position: absolute;
    top: 200%;
}
.footer * .github-repo {
    transform: scale(0.3);
}
.footer .mobile .github-repo {
    position: absolute;
    bottom: -2%;
    left: -4%;
}
.footer .desktop {
    width: 100%;
    position: absolute;
    bottom: 0; left: 0;
    background: #222;
    height: 4.5%;
    
}
div.icons a img {
    width: 12%;
}
div.icons a *:not([src="https://moondust-starlight.dx-assets.pages.dev/github-mark-white.svg"],[src="https://moondust-starlight.dx-assets.pages.dev/steam-logo-white.png"]) {
    filter: invert(1);
}
[src="https://moondust-starlight.dx-assets.pages.dev/steam-logo-white.png"] {
    transform: scale(2);
}
[href="https://discord.com/users/445565647653699594"] > img {
    transform: scale(1.1);
    margin-bottom: 1%;
}
[href="https://discord.com/users/445565647653699594"] {
  filter: invert(1);
}
div.icons a {
    margin-right: 3%;
    user-select: none;
}
div.icons a:last-child {
    margin-right: 0;
}
[src="https://moondust-starlight.dx-assets.pages.dev/threads.png"] {
    transform: scale(1.1);
}
.card:first-child .card-body {
    margin-top: -5%;
}
.card:not(.card:first-child) .card-body {
    font-size: 36pt;
}
div.icons {
    margin-top: 5%;
    display: inline-block;
}
div.cards *.card {
    margin-top: 5%;
}
div.cards .card:not(:nth-child(-n+2)) {
    margin-top: 2%;
}
div.card-title span {
    float: right;
    padding: 0;
}
.card {
    opacity: 0;
    animation: fadei 1s ease-in-out forwards;
    position: relative;
}
  
@keyframes fadei{
    0% {
        left: -5%;
      opacity: 0;
    }
    100% {
        left: 0%;
      opacity: 1;
    }
}
  
.card:nth-child(1) {
    animation-delay: 0s;
}
  
.card:nth-child(2) {
    animation-delay: 0.5s;
}
  
.card:nth-child(3) {
    animation-delay: 1s;
}

.card:nth-child(4) {
    animation-delay: 1.5s;
}
  
.card:nth-child(5) {
    animation-delay: 2s;
}